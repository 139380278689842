var Poc;

(function($){

	Poc = {

		$header : null,
		$menu : null,

		init : function(){

			this.$header = $( 'body > header' ).first();

			// Bind menu
			this.bindMenu();

		},

		bindMenu : function(){

			this.$menu = this.$header.children( 'div' );
			this.$menuTrigger = $( 'h1', this.$menu );
			this.$menuTrigger.on( 'click', function(){
				Poc.$menu.toggleClass( 'opened' );
			});

		}

	};

	$(function(){
		Poc.init();
	});

})(jQuery);